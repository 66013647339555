// 3rd party
import React from "react"
import parse from "html-react-parser"

// components
import Link from "./link"
import ExternalLink from "./externalLink"

export default function WpContent({content, replaceLinks = true, skipLinks = false}) {
  let output = parse(content, {
    replace: domNode => {
      if (domNode.name === "a") {
        let href = domNode.attribs.href
        let isInternal = href.startsWith("https://draax.nl/")
        let linkTitle = domNode.children[0].data
        if (skipLinks) {
          return <>{linkTitle}</>
        } else if (replaceLinks) {
          return isInternal ? (
            <Link uri={href.replace("https://draax.nl", "")}>{linkTitle}</Link>
          ) : (
            <ExternalLink url={href}>{linkTitle}</ExternalLink>
          )
        }
      }
    }
  })
  return output
}
