// 3rd party
import styled from "styled-components"

// constants
import {colors, device, sizes} from "../../../constants/css"

const OneCol = styled.div`
  background-color: ${colors.background};
  padding: ${sizes.padding}px ${sizes.padding}px 0 ${sizes.padding}px;
  width: 100%;
  margin: 0 auto;

  @media ${device.tabletM} {
    width: 630px;
  }

  @media ${device.tabletL} {
    width: ${({isHalf}) => (isHalf ? 0.5 : 1) * 780}px;
  }

  @media ${device.laptop} {
    width: ${({isHalf}) => (isHalf ? 0.5 : 1) * 1030}px;
  }
`

export default OneCol
