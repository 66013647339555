import React, {useState} from "react"
import styled from "styled-components"
import WpContent from "../general/wpContent"
import {colors} from "../../constants/css"

function addDays(date, days) {
  date.setDate(date.getDate() + days)
  return date
}

function Events({events}) {
  const [isShowsVisible, setIsShowsVisible] = useState(false)
  return (
    <>
      <ShowsTitle isActive={isShowsVisible} onClick={() => setIsShowsVisible(!isShowsVisible)}>
        live events, shows, etc.
      </ShowsTitle>

      {isShowsVisible && (
        <Shows>
          {events.map(({node: event}, i) => {
            let currentDate = typeof window !== "undefined" && new Date()
            let from = event.event.date.split(" ")
            let eventDate = typeof window !== "undefined" && new Date(from[2], from[1] - 1, from[0])
            let isInFuture = addDays(eventDate, 1) > currentDate
            return (
              isInFuture && (
                <Event key={i}>
                  {event.event.date && <DateSpan>{event.event.date}</DateSpan>}
                  {event.title && <h3>{event.title}</h3>}
                  {event.content && <WpContent content={event.content} />}
                </Event>
              )
            )
          })}
        </Shows>
      )}
    </>
  )
}
const Shows = styled.div`
  padding-top: 15px;
`

const ShowsTitle = styled.h3`
  text-transform: ${({isActive}) => (isActive ? "uppercase" : "none")};
  cursor: pointer;
  padding-left: 15px;
  position: relative;
  &:before {
    content: "";
    transform: rotate(${({isActive}) => (isActive ? 90 : 0)}deg);
    position: absolute;
    left: 0px;
    top: 2px;
    transition: 0.2s;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 12px solid ${colors.white};
  }
`

const Event = styled.div`
  padding-bottom: 15px;
`

const DateSpan = styled.span`
  background-color: ${colors.red};
  padding: 2px 5px;
  margin-bottom: 4px;
  display: inline-block;
`

export default Events
