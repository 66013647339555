// 3rd party
import React, {useContext, useRef} from "react"
import styled from "styled-components"
import loadable from "@loadable/component"

// constants
import {device, colors, sizes, transition} from "../../constants/css"

// components
import NavPreview from "./navPreview.js"
//import Scrollable from "../general/scrollable"
import NavSub from "./navSub"
import Diamond from "../icons/diamond"
import HandleClickOutside from "../general/handleClickOutside"

// context
import {GlobalStateContext, GlobalDispatchContext} from "../../context/GlobalContextProvider"
import {NavStateContext, NavDispatchContext} from "../../context/NavContextProvider"

// lazy
// const LazyNavMain = typeof window !== "undefined" && React.lazy(() => import("./navMain"))
const LazyNavMain = loadable(() => import("./navMain"))

const Nav = ({children}) => {
  // state
  const appState = useContext(GlobalStateContext)
  const appDispatch = useContext(GlobalDispatchContext)
  const navState = useContext(NavStateContext)
  const navDispatch = useContext(NavDispatchContext)
  // hooks
  let scrollRef = useRef()
  let activeRef = useRef()

  // functions
  const handleClick = e => {
    //console.log("handleclick outside nav", e.target, e.target.attributes, e.target.nodeName, e.target.parentNode.nodeName)
    if (e.target.nodeName !== "BUTTON" && e.target.parentNode.nodeName !== "BUTTON" && !e.target.classList.contains("no-exit")) {
      appDispatch({type: "collapseNav"})
      setTimeout(() => {
        appDispatch({type: "unfreezeNav"})
      }, transition.nav)
    }
  }

  function handleScroll() {
    let top = activeRef.current?.getBoundingClientRect().top
    let topOffset = scrollRef.current?.offsetTop
    top = top < topOffset ? -500 : top
    navDispatch({type: "setTop", top: top})
  }

  return (
    <div
      role="presentation"
      onMouseEnter={() => {
        if (appState.navIsCollapsed && !appState.navIsFrozen) {
          appDispatch({type: "decollapseNav"})
          setTimeout(() => {
            appDispatch({type: "unfreezeNav"})
          }, transition.nav)
        }
      }}
    >
      <HandleClickOutside onClickOutside={e => handleClick(e)}>
        {navState.previewPost && !appState.navIsCollapsed && <NavPreview />}
        <NavBar isCollapsed={appState.navIsCollapsed}>
          <Relative>
            {children}

            <NavMainWrapper>
              {typeof window !== "undefined" && <LazyNavMain {...{activeRef, scrollRef, handleScroll}} />}
            </NavMainWrapper>
            {!!navState.main[appState.navType] && !!navState.top && (
              <DiamondWrapper top={navState.top} collapsed={appState.navIsCollapsed ? 1 : 0}>
                <Diamond />
              </DiamondWrapper>
            )}
          </Relative>
        </NavBar>
        <NavSub />
      </HandleClickOutside>
    </div>
  )
}
const Relative = styled.div`
  position: relative;
  height: 100%;
  background-color: ${colors.background};
`
const NavMainWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  padding-top: 132px;
  @media ${device.tabletM} {
    grid-template-columns: min-content auto;
    padding-top: 0;
  }
`

const NavBar = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;

  transition: all ${transition.nav}ms;
  transform: translateX(${({isCollapsed}) => (isCollapsed ? -sizes.nav0Width.small + sizes.nav0WidthCollapsed.small : 0)}px);
  @media ${device.tabletM} {
    transform: translateX(${({isCollapsed}) => (isCollapsed ? -sizes.nav0Width.large + sizes.nav0WidthCollapsed.large : 0)}px);
  }
`

const DiamondWrapper = styled.div`
  width: ${sizes.navDiamondWidth}px;
  z-index: 2;
  position: fixed;
  top: ${({top}) => top + 3}px;
  left: ${sizes.nav0Width.small - sizes.navDiamondWidth + 2}px;
  @media ${device.tabletM} {
    left: ${sizes.nav0Width.large - sizes.navDiamondWidth - 3}px;
  }

  transform: ${({collapsed}) => (collapsed ? "translateX(" + -sizes.navDiamondWidth / 2 + "px)" : "")};
  transition: all ${transition.fast}ms;
`

export default Nav
