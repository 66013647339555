// 3rd party
import React from "react"
import styled from "styled-components"

// components
import AttachedWork from "../attached/attachedWork"
import NavLinkWrapper from "./navLinkWrapper"
import WpContent from "../general/wpContent"
import FadeInImage from "../general/fadeInImage"
import Link from "../general/link"
import {getImage} from "../../lib/dataManipulation"

const NavPreviewContent = ({post, attached}) => {
  const text = post?.description?.content
  const imageData = post.image?.node //post.mainSub?.mainSub ? post.mainSub.mainSub[0].image?.node : post.previewImage?.node
  let {width, height} = imageData?.mediaDetails || {width: 1, height: 1}
  let image = getImage(imageData, "medium_large")
  return (
    <>
      <NavLinkWrapper>
        {image && (
          <Link uri={post.uri}>
            <ImgWrapper>
              <FadeInImage src={image.sourceUrl} width={width} aspectRatio={width / height} />
            </ImgWrapper>
          </Link>
        )}
      </NavLinkWrapper>
      {!!text && <WpContent content={text} />}
      {!!attached.length && (
        <AttachedWork attached={attached} inNav={true} firstId={post.mainSub?.mainSub?.length && post.mainSub.mainSub[0].id} />
      )}
    </>
  )
}

const ImgWrapper = styled.div`
  margin-bottom: 15px;
`

export default NavPreviewContent
