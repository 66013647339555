// 3rd party
import React, {useState} from "react"
import styled from "styled-components"

// components
import ArchiveLi from "../../archive/archiveLi"
import ArchiveList from "../../archive/archiveList"
import OneCol from "../styled/oneCol"
import WorkContentWrapper from "./workContentWrapper"

// utils
import {getImage} from "../../../lib/dataManipulation"
import WorkThumbContent from "../workThumb/workThumbContent"

function WorkContentThumbs({content, setImageNum, imageNum, setIsModalOpen, title}) {
  let handleClick = i => {
    setImageNum(i)
    setIsModalOpen(false)
  }
  let [activeItem, setActiveItem] = useState()

  return (
    <WorkContentWrapper subtitle="thumbs" {...{title, setIsModalOpen}}>
      <OneCol>
        <ArchiveList>
          {content.map((item, i) => {
            let image = getImage(item.image, "thumbnail")
            let isVisible = i === imageNum || i === activeItem

            return (
              <ArchiveLi visible={isVisible} key={i} onMouseEnter={() => setActiveItem(i)} onMouseLeave={() => setActiveItem()}>
                <Button onClick={() => handleClick(i)}>
                  <WorkThumbContent image={image} size={200} isImage={!!image} />
                  {/*image?.sourceUrl && <FadeInImage src={image.sourceUrl} />*/}
                </Button>
              </ArchiveLi>
            )
          })}
        </ArchiveList>
      </OneCol>
    </WorkContentWrapper>
  )
}

const Button = styled.button`
  display: block;
  width: 100%;
  height: 100%;
`

export default WorkContentThumbs
