// 3rd party
import React, {useEffect, useState, useContext} from "react"

// context
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

// components
import Scrollable from "../../general/scrollable"
import BelowMenu from "../belowMenu"
import RenderResults from "./renderResults"

const SearchResults = ({isLoading, setIsLoading}) => {
  // state
  const appState = useContext(GlobalStateContext)
  let [usedQuery, setUsedQuery] = useState("")

  // effect
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setUsedQuery(appState.searchQuery)
      setIsLoading(false)
    }, 600)
  }, [appState.searchQuery, setIsLoading])

  return (
    <BelowMenu scrollable={true} visible={appState.searchQuery && appState.searchIsActive}>
      <div style={{display: "flex", height: "100%"}}>
        <Scrollable>
          <RenderResults query={usedQuery} {...{isLoading}} />
        </Scrollable>
      </div>
    </BelowMenu>
  )
}

export default SearchResults
