// 3rd party
import React, {createContext} from "react"
import {useImmerReducer} from "use-immer"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const GlobalContextProvider = ({children}) => {
  const navTemplate = {
    project: 0,
    medium: 0
  }

  const initialState = {
    attached: [],
    page: {main: navTemplate, sub: navTemplate, template: "", currentId: 0},
    navType: "medium",
    navIsCollapsed: true,
    navIsFrozen: false,
    navData: {medium: [], project: [], works: []},
    isInfoOpen: false,
    searchQuery: "",
    searchIsActive: false
  }

  function ourReducer(draft, action) {
    let debug = false
    switch (action.type) {
      case "setSearchQuery":
        debug && console.log("setSearchQuery")
        draft.searchQuery = action.value
        return
      case "setIsInfoOpen":
        debug && console.log("setIsInfoOpen")
        draft.isInfoOpen = action.value
        return
      case "setSearchIsActive":
        debug && console.log("setSearchIsActive")
        draft.searchIsActive = action.value
        return
      case "setAttached":
        debug && console.log("setAttached")
        draft.attached = action.value
        return
      case "setPage":
        debug && console.log("setPage")
        draft.searchIsActive = false
        draft.page = action.value.page
        draft.navIsCollapsed = true
        if (action.value.type) {
          draft.navType = action.value.type
        }
        return
      case "unfreezeNav":
        debug && console.log("unfreezeNav")
        draft.navIsFrozen = false
        return
      case "decollapseNav":
        debug && console.log("decollapseNav")
        draft.navIsCollapsed = false
        draft.navIsFrozen = true
        return
      case "setNavType":
        debug && console.log("setNavType")
        const isCollapsed = draft.navIsCollapsed
        const currentMenuIsClicked = draft.navType === action.value

        if (!isCollapsed && currentMenuIsClicked) {
          // if clicked menu is already open, close the menu
          debug && console.log("close menu")
          draft.navIsCollapsed = true
        } else {
          if (!currentMenuIsClicked) {
            // the menu is closed. the navtype is changed. do not open the menu.
            debug && console.log("change menu")
            draft.navType = action.value
          } else {
            // the menu is closed, so open the clicked menu
            debug && console.log("open menu")
            draft.navType = action.value
            draft.navIsCollapsed = false
          }
        }
        return
      case "collapseNav":
        debug && console.log("collapseNav")
        draft.navIsCollapsed = true
        draft.navIsFrozen = true
        return
      case "setNavData":
        debug && console.log("setNavData")
        draft.navData = action.value
        return
      default:
        return
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
