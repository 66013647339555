import React, {Component} from "react"
import styled from "styled-components"

// https://stackoverflow.com/questions/42012130/how-to-detect-overflow-of-react-component-without-reactdom
// https://codesandbox.io/s/1o0k7vr1m3?file=/src/overflowText.js:648-702
export default class LinkText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overflowActive: false
    }
    this.text = props.text
    this.active = props.active
    this.dataUri = props.dataUri
    this.dataId = props.dataId
  }

  isEllipsisActive(e) {
    return 210 < e.scrollWidth
  }

  componentDidMount() {
    this.setState({overflowActive: this.isEllipsisActive(this.span)})
  }
  componentDidUpdate(prevProps) {
    if (prevProps.resource !== this.props.resource) {
      this.setState({overflowActive: this.isEllipsisActive(this.span)})
    }
  }

  render() {
    let text = this.text
    if (this.state.overflowActive === true && !this.active) {
      text = this.text // + "..."
    } else {
      text = this.text
    }
    return (
      <Text
        style={{
          width: "100%", //this.active ? "190px" : "200px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
        ref={ref => (this.span = ref)}
      >
        <TextInner
          dangerouslySetInnerHTML={{__html: text}}
          overflown={this.state.overflowActive}
          data-uri={this.dataUri}
          data-id={this.dataId}
          data-is-sub={true}
        />
      </Text>
    )
  }
}

const overflowStyles = `&:hover {transform: translateX(calc(200px - 100%))};`

const TextInner = styled.span`
  position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  transition: 1s;
  padding-right: 7px;
  ${({overflown}) => overflown && overflowStyles}
`
const Text = styled.div`
  overflow: hidden;
  position: relative;
  height: 18px;
`
