import React, {createContext} from "react"
import {useImmerReducer} from "use-immer"
export const MenuStateContext = createContext()
export const MenuDispatchContext = createContext()

const MenuContextProvider = ({children}) => {
  const initialState = {
    menuIsVisible: true,
    hamburgerIsVisible: false
  }

  function ourReducer(draft, action) {
    let debug = false
    //console.log('menuContext')
    switch (action.type) {
      case "showCross":
        debug && console.log("showCross")
        draft.hamburgerIsVisible = true
        return
      case "hideCross":
        debug && console.log("hideCross")
        draft.hamburgerIsVisible = false
        return
      case "toggleMenu":
        debug && console.log("toggleMenu")
        draft.menuIsVisible = !draft.menuIsVisible
        draft.hamburgerIsVisible = false
        return
      default:
        return
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  return (
    <MenuStateContext.Provider value={state}>
      <MenuDispatchContext.Provider value={dispatch}>{children}</MenuDispatchContext.Provider>
    </MenuStateContext.Provider>
  )
}

export default MenuContextProvider
