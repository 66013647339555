// 3rd party
import React from "react"
import styled from "styled-components"
import SpotifyPlayer from "react-spotify-player"

// components
import {getBandcampSource} from "../../lib/embeds"

let height = {large: 472, small: 85}
let width = 400

function EmbedAudio({audio, autoplay, isLarge}) {
  let spotifySize = {width: "100%", height: isLarge ? height.large : height.small}
  let bandcampSource = getBandcampSource(audio)
  let bandcampStyle = {border: 0, width: isLarge ? 350 : 170 + "px", height: (isLarge ? height.large : 170) + "px"}
  return (
    <AudioContent width={width} height={height.large}>
      {audio.includes("bandcamp.com") && <iframe title="bandcamp embed" src={bandcampSource} seamless style={bandcampStyle} />}
      {audio.includes("spotify") && <SpotifyPlayer uri={audio} size={spotifySize} />}
    </AudioContent>
  )
}

const AudioContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  //height: ${({height}) => (height ? height : 500)}px;
  //width: ${({width}) => (width ? width : 700)}px;
  transform: translate(-50%, -50%);
`

export default EmbedAudio
