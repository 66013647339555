//  utils
import {getWorkUri} from "./utils"

export function normalizeSearchData(searchData) {
  let normalizedData = []
  let types = ["medium", "project", "works"]
  types.forEach(type => {
    searchData[type].forEach(({node: post}, i) => {
      normalizedData.push({...post, type: type})
    })
  })
  return normalizedData
}

function cleanString(string) {
  return string?.toLowerCase().replaceAll("’", "'").replaceAll("‘", "'")
}

export function search(items, query) {
  const matchedItemsByTitle = items.filter(item => cleanString(item.title)?.includes(cleanString(query)))
  const matchedItemsByDescription = items.filter(item => cleanString(item.description.content)?.includes(cleanString(query)))
  const filteredItems = matchedItemsByTitle.concat(matchedItemsByDescription)
  let uniqueFilteredItems = [...new Set(filteredItems)]
  let mediumResults = uniqueFilteredItems.filter(res => res.type === "medium")
  mediumResults.forEach((result, i) => {
    let parentMedium = result.parent?.node
    let fullParentMedium = items.filter(item => item.type === "medium" && item.id === parentMedium?.id)
    fullParentMedium = fullParentMedium.length && fullParentMedium[0]
    mediumResults[i].title =
      (!!fullParentMedium?.medium?.singular ? fullParentMedium?.medium?.singular + ": " : "") + mediumResults[i].title
  })
  let projectResults = uniqueFilteredItems.filter(res => res.type === "project")
  projectResults.forEach((result, i) => {
    let parentProject = result.parent?.node
    let fullParentProject = items.filter(item => item.type === "project" && item.id === parentProject?.id)
    fullParentProject = fullParentProject.length && fullParentProject[0]
    projectResults[i].title = (!!fullParentProject?.title ? fullParentProject?.title + ": " : "") + projectResults[i].title
  })
  let workresults = uniqueFilteredItems.filter(res => res.type === "works")
  workresults.forEach((result, i) => {
    let medium = result.medium.nodes[0]
    let fullMedium = items.filter(item => item.type === "medium" && item.id === medium?.id)
    fullMedium = fullMedium.length && fullMedium[0]
    let project = result.project.nodes[0]
    let uri = getWorkUri(result, medium?.id, project?.id)
    workresults[i].title = (!!fullMedium?.medium?.singular ? fullMedium?.medium?.singular + ": " : "") + workresults[i].title
    workresults[i].uri = uri
  })
  let resultsSorted = {
    medium: mediumResults,
    project: projectResults,
    work: workresults
  }
  return resultsSorted
}

/*
import * as JsSearch from "js-search"

export function normalizeSearchData(searchData) {
  let searchProjectData = []
  searchData.project.edges.forEach(({node: project}, i) => {
    let parentTitle = project.parent?.node.title
    searchProjectData.push(project)
    searchProjectData[i] = {...searchProjectData[i]} //, project.description.text}
    searchProjectData[i].description = project.description?.text || ""
    searchProjectData[i].type = "project"
    searchProjectData[i].title = (parentTitle ? parentTitle + ": " : "") + searchProjectData[i].title

    //searchProjectData[i].id = searchProjectData[i].id + 100000
  })
  let searchMediumData = []
  searchData.medium.edges.forEach(({node: medium}, i) => {
    let parentTitle = medium.parent?.node.title
    searchMediumData.push(medium)
    searchMediumData[i] = {...searchMediumData[i]} //, project.node.description.text}
    searchMediumData[i].description = medium.description?.text || ""
    searchMediumData[i].type = "medium"
    searchMediumData[i].title = (parentTitle ? parentTitle + ": " : "") + searchMediumData[i].title
    //searchMediumData[i].id = searchMediumData[i].id + 200000
  })
  let searchWorkData = []
  searchData.works.edges.forEach(({node: work}, i) => {
    let mediumTitle = work.mediums.nodes[0]?.medium.singular
    searchWorkData.push(work)
    searchWorkData[i] = {...searchWorkData[i]} //, project.node.description.text}
    searchWorkData[i].description = work.description?.text || ""
    searchWorkData[i].type = "work"
    searchWorkData[i].title = (mediumTitle ? mediumTitle + ": " : "") + searchWorkData[i].title

    //searchWorkData[i].id = searchWorkData[i].id + 300000
  })
  let structuredSearchData = searchProjectData.concat(searchMediumData).concat(searchWorkData)
  return structuredSearchData
}

// rebuilds the overall index based on the options
export function rebuildIndex(searchData) {
  const search = new JsSearch.Search("id")
  search.indexStrategy = new JsSearch.PrefixIndexStrategy()
  search.sanitizer = new JsSearch.LowerCaseSanitizer()
  search.searchIndex = new JsSearch.TfIdfSearchIndex("id")
  search.addIndex("title") // sets the index attribute for the data
  search.addIndex("description")
  //search.addIndex("author") // sets the index attribute for the data

  //search.addDocuments(projectList) // adds the data to be searched
  search.addDocuments(searchData) // adds the data to be searched
  return search

}
*/
