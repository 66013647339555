import {colors, device, sizes} from "../../constants/css"
import React from "react"
import styled from "styled-components"

function ArchiveLi({children, large, visible, active, isHidden, onMouseEnter, onMouseLeave}) {
  return (
    <Li {...{large, active}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Overlay {...{visible, isHidden}} />
      <Inner>{children}</Inner>
    </Li>
  )
}
const Inner = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: ${colors.black};
  z-index: ${({visible, isHidden}) => (!visible || isHidden ? 2 : 0)};
  transition: opacity 0.3s;
  opacity: ${({visible, isHidden}) => (isHidden ? 0.9 : visible ? 0 : 0.7)};
`

const Li = styled.li`
  position: relative;
  background-color: ${colors.black};
  width: 33.33%;
  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  //width: ${({large}) => (large ? 2 : 1) * sizes.archive.small}px;
  //height: ${({large}) => (large ? 2 : 1) * sizes.archive.small}px;
  @media ${device.mobileL} {
    width: ${({large}) => (large ? 2 : 1) * sizes.archive.medium}px;
    height: ${({large}) => (large ? 2 : 1) * sizes.archive.medium}px;
  }
  @media ${device.laptop} {
    width: ${({large}) => (large ? 2 : 1) * sizes.archive.large}px;
    height: ${({large}) => (large ? 2 : 1) * sizes.archive.large}px;
  }
  border: 1px solid ${({active}) => (active ? colors.white : "transparent")};

  outline: 1px solid grey;
  outline-offset: -2px;
  border-bottom: none;
  &:hover {
    z-index: ${({active}) => (active ? 20 : 1)};
  }
`
export default ArchiveLi
