import React, {useEffect, useRef} from "react"

function HandleClickOutside({children, onClickOutside}) {
  const myRef = useRef()
  // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
  useEffect(() => {
    const handleClick = e => {
      if (myRef.current?.contains(e.target)) {
        // inside click
        return
      } else {
        // outside click
        // console.log("outside")
        onClickOutside(e)
      }
    }

    document.addEventListener("mousedown", handleClick)
    return () => document.removeEventListener("mousedown", handleClick)
  }, [onClickOutside])

  return <div ref={myRef}>{children}</div>
}

export default HandleClickOutside
