export const Const = {
  lang: "en"
}

export const transition = {
  nav: 750,
  fast: 200,
  gallery: 400,
  workContent: 700
}
export const sizes = {
  left: {small: 35, large: 65},
  bottom: {small: 50, large: 50},
  top: {small: 65, large: 50},
  menuWidth: 265,
  nav0Width: {
    small: 200,
    large: 292
  },

  nav0WidthCollapsed: {small: 25, large: 35},
  nav1Width: {
    small: 160,
    large: 234
  },
  thumb: 70,
  thumbMargin: 10,
  nav1WidthSmall: {small: 12, large: 17},
  nav1ImgBorder: 7,
  nav1ImgWidth: 84,
  nav1ImgOffset: 6,
  navDiamondWidth: 10,
  nav2Width: 543,
  padding: 15,
  attachedWidth: 84,
  attachedOffset: 25,
  attachedSpacer: 20,
  archive: {
    small: 100,
    medium: 150,
    large: 200
  }
}
sizes.attachedMargin = sizes.attachedWidth + sizes.attachedOffset + sizes.attachedSpacer
sizes.splashHorizontalMargin = {small: sizes.left.small, large: sizes.left.large + sizes.attachedMargin}
sizes.bottomTotal = {small: sizes.bottom.small + sizes.thumb + sizes.thumbMargin}
sizes.splashVerticalMargin = {
  small: sizes.top.small + sizes.bottomTotal.small,
  large: sizes.top.large + sizes.bottom.large
}

export const colors = {
  red: "#CD2027",
  black: "#000",
  white: "#fff",
  background: "rgba(0, 0, 0, 0.80)",
  bgMedium: "rgba(0, 0, 0, 0.5)",
  bgLight: "rgba(0, 0, 0, 0.2)"
}

export const fonts = {
  main: "'Prompt', sans-serif"
}

export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 485,
  tabletS: 600,
  tabletM: 768,
  tabletL: 900,
  laptop: 1074,
  laptopL: 1170,
  desktop: 1440
}

export const device = {
  /* https://jsramblings.com/how-to-use-media-queries-with-styled-components/ */

  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  tabletS: `(min-width: ${size.tabletS}px)`,
  tabletM: `(min-width: ${size.tabletM}px)`,
  tabletL: `(min-width: ${size.tabletL}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktop}px)`,
  wideEnoughForSidebar: `(min-width: ${size.mobileL}px)`
}

export default Const
