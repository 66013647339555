// 3rd party
import React from "react"

// components

function Play() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.7 56.7" fill="#fff" width="100%">
      <path
        d="M28.5,51.2c-12.8,0-23.2-10.4-23.2-23.2c0-12.8,10.4-23.2,23.2-23.2c12.8,0,23.2,10.4,23.2,23.2
	C51.7,40.8,41.3,51.2,28.5,51.2z M28.5,7.7c-11.2,0-20.4,9.1-20.4,20.4s9.1,20.4,20.4,20.4s20.4-9.1,20.4-20.4S39.8,7.7,28.5,7.7z"
      />
      <g>
        <polygon points="36,27.5 30.5,30.7 24.9,33.9 24.9,27.5 24.9,21 30.5,24.2 	" />
      </g>
    </svg>
  )
}

export default Play
