import {colors} from "../constants/css"

export function getBandcampSource(url) {
  let colCode = colors.red.replace("#", "")
  url = url.replace("http://", "https://")
  let defaultProps = {
    size: "large",
    bgcol: "000000",
    linkcol: colCode,
    minimal: "true",
    transparent: "true"
  }
  let baseUrl = "https://bandcamp.com/EmbeddedPlayer/"
  if (url.startsWith("https://bandcamp.com/EmbeddedPlayer/")) {
    let rest = url.substring(baseUrl.length)
    let parameters = rest.split("/")
    let obj = {}
    parameters.forEach(parameter => {
      let [key, val] = parameter.split("=")
      if (!!key && !!val) {
        obj[key] = val
      }
    })

    for (const [key, value] of Object.entries(defaultProps)) {
      obj[key] = value
    }
    let outputUrl = baseUrl
    for (const [key, value] of Object.entries(obj)) {
      outputUrl += `${key}=${value}/`
    }
    return outputUrl
  }
}

export function getVimeoSourceUrl(url) {
  let baseUrl = "https://vimeo.com/"
  if (url.startsWith(baseUrl)) {
    let videoId = url.substring(baseUrl.length)
    videoId = videoId.split("/")[0]
    if (parseInt(videoId)) {
      return `https://player.vimeo.com/video/${videoId}`
    }
  }
}
