function distinct(array) {
  return array.filter((item, i, ar) => ar.indexOf(item) === i) // unique values
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj.node[prop]).indexOf(obj.node[prop]) === pos
  })
}

function stripNode(array) {
  // convert  array of nodes with a post to array of actual posts
  array.forEach(({node}, i) => (array[i] = node))
  return array
}

function selectParagraph(text, query) {
  let termIndex = text.toUpperCase().indexOf(query.toUpperCase())
  if (!!termIndex) {
    let firstPart = text.slice(0, termIndex)
    let lastPart = text.slice(termIndex, text.length)
    let pStartIndex = firstPart.lastIndexOf("<p>")
    let pEndIndex = lastPart.indexOf("</p>") + termIndex + 4
    return text.slice(pStartIndex, pEndIndex)
  }
}

function trimParagraph(paragraph, query) {
  let offset = 75
  let index = paragraph.toUpperCase().indexOf(query.toUpperCase())
  if (index > offset + 5) {
    paragraph = "[...]" + paragraph.slice(index - offset, paragraph.length)
  }
  index = paragraph.toUpperCase().indexOf(query.toUpperCase())
  if (paragraph.length >= index + offset + query.length + 5) {
    paragraph = paragraph.slice(0, index + offset + 5) + "[...]"
  }
  return paragraph
}

function getSnippet(text, query) {
  let paragraph = selectParagraph(text, query)
  if (paragraph) {
    paragraph = paragraph.replace(/<[^>]+>/g, "")
    let snippet = trimParagraph(paragraph, query)
    return snippet
  }
}

function sluggify(text) {
  // convert text into slug-like form, so no caps, spaces, special characters
  if (text) {
    text = text
      .replace(/ /g, "-")
      .normalize("NFD")
      .replace(/[^a-zA-Z0-9-]/g, "")
      .toLowerCase()
      .replace(/--/g, "-")
  }
  return text
}

function splitText(text) {
  // split text into two parts.
  // if no text put in, return empty array
  if (!text) return [null, null]

  // remove html tag attributes
  text = text.replace(/ class="[^"]*"/g, "")

  let firstHalf = ""
  let secondHalf = ""

  // only split long texts
  let parts = text.split("</p>")
  let partsWithContent = parts.filter(part => part !== "\n")
  if (text.length > 500 && partsWithContent.length > 1) {
    // split text by paragraph
    let length = 0
    let middle = text.length / 2
    let lengths = [] //[middle]
    // for each part, calculate the offset from the middle
    parts.forEach(part => {
      length += part.length
      lengths.push(Math.abs(middle - length))
      //lengths.push(middle - length > 0 ? middle - length : middle)
    })

    // there should be a split where the offset to the middle is the smallest
    let middleIndex = lengths.indexOf(Math.min(...lengths))
    firstHalf = parts.slice(0, middleIndex + 1).join("</p>")
    secondHalf = parts.slice(middleIndex + 1, parts.length).join("</p>")
  } else {
    // if text is short, use full text as first half
    firstHalf = text
  }
  return [firstHalf, secondHalf]
}

var gcd = function (a, b) {
  //stackoverflow.com/questions/23575218/convert-decimal-number-to-fraction-in-javascript-or-closest-fraction
  if (b < 0.0000001) return a // Since there is a limited precision we need to limit the value.

  return gcd(b, Math.floor(a % b)) // Discard any fractions due to limitations in precision.
}

function getNumDenom(fraction) {
  // convert decimal number to approximated numerator and denominator
  var len = fraction.toString().length - 2
  var denominator = Math.pow(10, len)
  var numerator = fraction * denominator
  var divisor = gcd(numerator, denominator)
  numerator /= divisor
  denominator /= divisor
  return [Math.floor(numerator), Math.floor(denominator)]
}

function getWorkUri(work, mediumId, projectId, navType) {
  if (work) {
    let workTitle = sluggify(work.title)
    let uri = `/${work.id}/${workTitle}`
    if (mediumId) uri += `/?m=${mediumId}`
    if (projectId) uri += `${mediumId ? "&" : "/?"}p=${projectId}`
    if (navType === "medium" || navType === "project") uri += `${!!mediumId || !!projectId ? "&" : "?/"}t=${navType}`
    return uri
  }
}

// detect swipe
function getTouchCoordinates(e) {
  return {x: e.touches[0].clientX, y: e.touches[0].clientY}
}

function getMouseCoordinates(e) {
  return {x: e.clientX, y: e.clientY}
}

module.exports.getWorkUri = getWorkUri
module.exports.distinct = distinct
module.exports.removeDuplicates = removeDuplicates
module.exports.getSnippet = getSnippet
module.exports.stripNode = stripNode
module.exports.getNumDenom = getNumDenom
module.exports.splitText = splitText
module.exports.getTouchCoordinates = getTouchCoordinates
module.exports.getMouseCoordinates = getMouseCoordinates
