import React from "react"
import styled from "styled-components"

const NavLinkWrapper = ({children}) => {
  return (
    <Wrapper role="button" tabIndex="0">
      {children}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  outline: none;
  a {
    text-decoration: none;
  }
`

export default NavLinkWrapper
