// 3rd party
import React from "react"

function Project({isLarge}) {
  return (
    <svg height={isLarge ? 28 : 15} version="1.1" viewBox="0 0 140.04 25.827" xmlns="http://www.w3.org/2000/svg" fill="#fff">
      <defs>
        <clipPath id="clipPath68">
          <path d="m0 0h1366v768h-1366z" />
        </clipPath>
        <clipPath id="clipPath96">
          <path d="m0 0h1366v768h-1366z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333 0 0 -1.3333 -173.38 918.75)">
        <g transform="translate(142.15 685.86)">
          <path d="m0 0v-1.886c0-2.144-0.749-2.351-2.661-3.538l-5.708-3.565v-5.423h1.679v-1.756h-5.423v1.756h1.627v15.832h-1.627v1.782h9.22c2.273 0 2.893-0.646 2.893-3.202m-1.886 1.42h-6.483v-8.342l6.483 4.003z" />
        </g>
        <g transform="translate(161.62 671.45)">
          <path d="m0 0v-1.756h-5.114v1.756h1.395l-4.415 7.103-2.739-1.679v-5.424h1.679v-1.756h-5.424v1.756h1.627v15.858h-1.627v1.756h9.221c2.272 0 2.892-0.671 2.892-3.228v-1.602c0-2.582-0.852-2.711-4.029-4.675l5.088-8.109zm-10.873 7.49 6.482 4.004v4.364h-6.482z" />
        </g>
        <g transform="translate(173.11 689.06)">
          <path d="m0 0c2.091 0 2.996-1.161 2.996-3.435v-12.423c0-2.453-0.698-3.512-2.867-3.512h-4.959c-2.376 0-3.074 1.24-3.074 3.125v12.81c0 2.144 0.516 3.435 2.867 3.435zm-5.836-17.614h6.766v15.858h-6.766z" />
        </g>
        <g transform="translate(188.12 687.3)">
          <path d="m0 0h-1.68v-14.592c0-1.395-0.594-3.022-2.763-3.022h-3.849v1.756h4.495v15.858h-1.654v1.756h5.451z" />
        </g>

        <g transform="translate(202.27 682.42)">
          <path d="m0 0v-5.424h-1.757v1.834h-3.641v-7.387h6.612v3.022h1.756v-4.778h-12.114v1.756h1.627v15.858h-1.627v1.756h12.114v-4.778h-1.756v3.022h-6.612v-6.715h3.641v1.834z" />
        </g>
        <g transform="translate(217.35 674.47)">
          <path d="m0 0h1.757v-4.778h-7.93c-2.092 0-2.789 1.24-2.789 2.764v13.818c0 1.342 0.594 2.788 2.531 2.788h8.188v-4.778h-1.757v3.022h-6.844v-15.858h6.844z" />
        </g>
        <g transform="translate(226.18 671.45)">
          <path d="m0 0h1.628v15.858h-3.253v-3.048h-1.861v4.804h12.372v-4.804h-1.887v3.048h-3.254v-15.858h1.679v-1.756h-5.424z" />
        </g>
      </g>
    </svg>
  )
}

export default Project
