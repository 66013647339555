import React, {useContext} from "react"
import styled from "styled-components"
import {navigate} from "gatsby"
import {colors} from "../../constants/css"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {MenuStateContext, MenuDispatchContext} from "../../context/MenuContextProvider"
import Diamond from "../icons/diamond"

const MenuDiamond = ({children}) => {
  // state
  const menuState = useContext(MenuStateContext)
  const menuDispatch = useContext(MenuDispatchContext)
  const appState = useContext(GlobalStateContext)

  // functions
  let handleClick = e => {
    if (menuState.menuIsVisible) {
      // not going home
      menuDispatch({type: "toggleMenu"})
      e.preventDefault()
    } else {
      // probably going home
      navigate("/")
    }
  }

  return (
    <Wrapper
      onMouseEnter={() => menuDispatch({type: "showCross"})}
      onMouseLeave={() => menuDispatch({type: "hideCross"})}
      type={appState.navType}
    >
      <Inner>
        <DiamondWrapper type={appState.navType} onClick={handleClick} onKeyDown={() => navigate("/")}>
          <Diamond colorLeft={!menuState.menuIsVisible ? colors.black : colors.red} />
        </DiamondWrapper>
        {children}
      </Inner>
    </Wrapper>
  )
}

const Inner = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  transition: all 0.15s ease-out;
  right: ${({type}) => (type === "project" ? 64 : 152)}px;
  top: -31px;
  z-index: 2;
`

const DiamondWrapper = styled.div`
  transition: transform 600ms ease-in-out;

  cursor: ${({active}) => (active ? "move" : "pointer")};
  width: 2.0625rem;
`

export default MenuDiamond
