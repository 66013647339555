// 3rd party
import React, {useContext} from "react"
import styled from "styled-components"

// constants
import {colors, transition} from "../../constants/css"

// context
import {GlobalStateContext} from "../../context/GlobalContextProvider"

function Overlay({children}) {
  const appState = useContext(GlobalStateContext)
  let isSearching = appState.searchIsActive && !!appState.searchQuery
  return (
    <>
      {children}
      <StyledOverlay visible={!appState.navIsCollapsed || isSearching || appState.isInfoOpen} />
    </>
  )
}
const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: ${transition.nav}ms;
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  background-color: ${colors.background};
`

export default Overlay
