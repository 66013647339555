import styled, {css} from "styled-components"
import {colors} from "../../../constants/css"

const BelowMenuItem = styled.div`
  border-bottom: 1px solid ${colors.white};
  padding: 5px;
  &:last-of-type {
    border: none;
  }
  ${({canHover = false}) =>
    canHover &&
    css`
      transition: 0.25s;
      &:hover {
        background-color: ${colors.red};
      }
    `}
  ${({hasBackground = false}) =>
    hasBackground &&
    css`
      background-color: ${colors.red};
    `}
`

export default BelowMenuItem
