// 3rd party
import React from "react"

function Work({isLarge}) {
  return (
    <svg
      height={isLarge ? 28 : 15}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 752 222"
      fill="#fff"
    >
      <g>
        <path
          d="M233.2,0.4v20.1H219l-31.7,201.9h-38.5l-32-201.6l-32,201.6H46.4L14.7,20.5H0.5V0.4h48.9v20.1H37.5l28.1,181.8
		L96.7,0.4H137l31.1,201.9l28.1-181.8h-11.8V0.4H233.2z"
        />
        <path
          d="M339.2,0.4c24,0,34.3,13.3,34.3,39.4v142.4c0,28.1-8,40.3-32.9,40.3h-56.9c-27.2,0-35.2-14.2-35.2-35.8V39.8
		c0-24.6,5.9-39.4,32.9-39.4H339.2z M272.3,202.3h77.6V20.5h-77.6V202.3z"
        />
        <path
          d="M578.2,202.3v20.1h-58.6v-20.1h16l-50.6-81.4l-31.4,19.2v62.2h19.2v20.1h-62.2v-20.1h18.7V20.5h-18.7V0.4
		h105.7c26.1,0,33.2,7.7,33.2,37v18.4c0,29.6-9.8,31.1-46.2,53.6l58.3,93H578.2z M453.5,116.5l74.3-45.9v-50h-74.3V116.5z"
        />
        <path
          d="M752.9,202.3v20.1h-33.8L644.8,112v90.3h19.2v20.1h-62.2v-20.1h18.7V20.5h-18.7V0.4h62.2v20.1h-20.4v82.6
		L719.1,0.4h32v20.1h-19.8L669.7,107l64.3,95.3H752.9z"
        />
      </g>
    </svg>
  )
}

export default Work
