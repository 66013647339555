import {colors} from "../../constants/css"
import React from "react"
import styled, {css} from "styled-components"

function BelowMenu({children, scrollable = false, visible = true}) {
  return <Below {...{scrollable, visible}}>{children}</Below>
}

const Below = styled.div`
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  display: ${({visible}) => (visible ? "block" : "none")};
  position: absolute;
  background-color: ${colors.background};
  border: 2px solid ${colors.white};
  border-top: none;
  left: 0px;
  right: 0px;
  height: auto;

  ${({scrollable}) =>
    scrollable &&
    css`
      height: 75vh;
    `}
  transition: all 1s;
`

export default BelowMenu
