// globalStyles.js
import {device} from "../constants/css"
import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 16px;
  }

  html {
    font-size: 0.75rem;
    color: white;
    text-transform: lowercase;
  }

  body {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    font-size: 0.625rem;
    margin: 0;
    background-color: black;
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    font-weight: normal;
    word-wrap: break-word;
  }

  li {
    list-style: none;
  }

  p {
    text-transform: none;
    padding-bottom: 0.5rem;
    line-height: 1.7;
  }

  p:last-of-type {
    padding: 0;
  }

  a {
    color: white;
    text-decoration: underline;
    -webkit-text-decoration-skip: objects;
  }

  * {
    font-family: "Prompt", sans-serif;
    box-sizing: border-box;
    outline-color: transparent;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  section {
    padding-bottom: 100px;
  }

  div[role="button"] {
    outline: none;
  }

  img {
    max-width: 100%;
  }

  h1 {
    font-size: 1rem;
    font-weight: 700;
  }

  h2 {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 14px;
    text-transform: uppercase;
  }

  h3 {
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  h4 {
    font-size: 0.625rem;
    font-weight: 400;
    //text-transform: uppercase;
    font-style: italic;
  }

  h5 {
    font-size: 0.625rem;
    font-weight: 400;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .simple-link {
    text-decoration: none;
  }

  .body-no-scroll {
    overflow: hidden;
    margin-right: 17px;
  }

  .hide-scrollbar {
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }

  .relative {
    position: relative;
  }

  .lower {
    text-transform: lowercase;
  }

   .shift-enter {
    transform: translateY(100%) !important ;
    @media ${device.tabletM} {
      transform: translateX(-50%) translateY(100%) !important ;
    }
  }
  .shift-enter-active,
  .shift-enter-done {
    transform: translateY(0) !important;
    @media ${device.tabletM} {
      transform: translateX(-50%) translateY(0) !important;
    }
  }

  .shift-exit-active {
    transform: translateY(100%) !important;
    @media ${device.tabletM} {
      transform: translateX(-50%) translateY(100%) !important;
    }
  }
  .fade-enter {
    opacity: 0 !important;
  }
  .visible.fade-enter-active,
  .visible.fade-enter-done {
    opacity: 1 !important;
  }

  .fade-exit-active {
    opacity: 0 !important;
  }

  .from-mobile-l {
    display: none;
    visibility: hidden;
    @media ${device.mobileL} {
      display: unset;
      visibility: unset;
    }
  }

  .until-mobile-l {
    //display: block;
    //visibility: visible;
    @media ${device.mobileL} {
      display: none;
      visibility: hidden;
    }
  }

  .from-tablet-m {
    display: none;
    visibility: hidden;
    @media ${device.tabletM} {
      display: unset;
      visibility: unset;
    }
  }

  .until-tablet-m {
    //display: block;
    //visibility: visible;
    @media ${device.tabletM} {
      display: none;
      visibility: hidden;
    }
  }

  .from-laptop {
    display: none;
    visibility: hidden;
    @media ${device.laptop} {
      display: unset;
      visibility: unset;
    }
  }

  .until-laptop {
    //display: block;
    //visibility: visible;
    @media ${device.laptop} {
      display: none;
      visibility: hidden;
    }
  }

  .no-overflow {
    overflow: hidden;
  }
`
export default GlobalStyle
