import React, {createContext, useEffect, useContext} from "react"
import {useImmerReducer} from "use-immer"
import {GlobalStateContext} from "./GlobalContextProvider"
export const NavStateContext = createContext()
export const NavDispatchContext = createContext()

const NavContextProvider = ({children}) => {
  const appState = useContext(GlobalStateContext)

  const initialState = {
    subPosts: {medium: [], project: []},
    previewPost: null,
    main: appState.page.main,
    sub: appState.page.sub,
    top: null
  }
  function ourReducer(draft, action) {
    let debug = false
    switch (action.type) {
      case "setTop":
        debug && console.log("setTop", action.top)
        draft.top = action.top
        return
      case "setNavMain":
        debug && console.log("setNavMain", action.item.id)
        draft.main[appState.navType] = action.item.id

        let children = appState.navData[appState.navType].filter(({node}) => {
          return node.parent?.node.id === action.item.id
        })
        let hasChildren = !!children.length
        draft.subPosts[appState.navType] = hasChildren ? children : [{node: action.item}]
        draft.previewPost = !hasChildren ? action.item : null
        return
      case "setNavSub":
        debug && console.log("setNavSub")
        draft.sub[appState.navType] = action.id
        draft.previewPost = null
        return
      case "setPreview":
        debug && console.log("setPreview", action.post)
        draft.previewPost = action.post
        return
      case "resetNav":
        debug && console.log("resetNav")
        draft.main = action.value.main
        draft.sub = action.value.sub
        draft.previewPost = null
        return
      case "clearSubPosts":
        debug && console.log("clearSubPosts")
        draft.subPosts = {
          medium: [],
          project: []
        }
        return
      default:
        return
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  useEffect(() => {
    let {main, sub} = appState.page
    dispatch({type: "resetNav", value: appState.page})
    if (main.medium === 0 && main.project === 0 && sub.medium === 0 && sub.project === 0) {
      dispatch({type: "clearSubPosts"})
    }
  }, [appState.navIsCollapsed, appState.page, dispatch])

  return (
    <NavStateContext.Provider value={state}>
      <NavDispatchContext.Provider value={dispatch}>{children}</NavDispatchContext.Provider>
    </NavStateContext.Provider>
  )
}

export default NavContextProvider
