// 3rd party
import React from "react"
import styled from "styled-components"

// components
import {colors} from "../../constants/css"

function Spinner() {
  return (
    <Bg>
      <Dots>
        {[...Array(16).keys()].map(dot => {
          return <Dot n={dot + 1} key={dot} />
        })}
        {[...Array(4).keys()].map(bar => {
          return <Bar n={bar + 1} key={bar} />
        })}
      </Dots>
    </Bg>
  )
}

let duration = 1300

const Bar = styled.div`
  @keyframes bar-1 {
    0% {
      width: 100%;
    }
    6.25% {
      width: 100%;
    }
    6.25% {
      width: 75%;
    }
    12.5% {
      width: 75%;
    }
    12.5% {
      width: 50%;
    }
    18.75% {
      width: 50%;
    }
    18.75% {
      width: 25%;
    }
    25% {
      width: 0%;
    }
    100% {
      width: 0%;
    }
  }
  @keyframes bar-2 {
    0% {
      width: 100%;
    }
    25% {
      width: 100%;
    }
    31.25% {
      width: 100%;
    }
    31.25% {
      width: 75%;
    }
    37.5% {
      width: 75%;
    }
    37.5% {
      width: 50%;
    }
    43.75% {
      width: 50%;
    }
    43.75% {
      width: 25%;
    }
    50% {
      width: 0%;
    }
    100% {
      width: 0%;
    }
  }
  @keyframes bar-3 {
    50% {
      width: 100%;
    }
    56.25% {
      width: 100%;
    }
    56.25% {
      width: 75%;
    }
    62.5% {
      width: 75%;
    }
    62.5% {
      width: 50%;
    }
    68.75% {
      width: 50%;
    }
    68.75% {
      width: 25%;
    }
    75% {
      width: 0%;
    }
    100% {
      width: 0%;
    }
  }
  @keyframes bar-4 {
    0% {
      width: 100%;
    }
    75% {
      width: 100%;
    }
    81.25% {
      width: 100%;
    }
    81.25% {
      width: 75%;
    }
    87.5% {
      width: 75%;
    }
    87.5% {
      width: 50%;
    }
    93.75% {
      width: 50%;
    }
    93.75% {
      width: 25%;
    }
    100% {
      width: 0%;
    }
  }
  animation-name: bar-${({n}) => n};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: ${duration}ms;
  position: absolute;
  width: 100%;
  height: 25%;
  right: 0;
  top: ${({n}) => (n - 1) * 25}%;
  //background-color: ${colors.black};
`

const Dots = styled.div`
  position: relative;
  display: grid;
  min-width: 50px;
  min-height: 50px;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
`

const Dot = styled.div`
  width: 75%;
  height: 75%;
  background-color: ${colors.white};
  border-radius: 50%;
`

const Bg = styled.div`
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    50% {
      transform: rotateZ(-180deg);
    }
    100% {
      transform: rotateZ(-360deg);
    }
  }
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: ${1.25 * duration}ms;
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
`

export default Spinner
