// 3rd party
import React from "react"
import styled from "styled-components"

// constants
import {colors, sizes} from "../../../constants/css"

// components
import Scrollable from "../../general/scrollable"

function Tab({subtitle, title}) {
  return (
    <TabWrapper>
      <h4>{subtitle}</h4>
      <h2>{title}</h2>
    </TabWrapper>
  )
}

function WorkContentWrapper({children, title, subtitle, setIsModalOpen}) {
  return (
    <>
      <TabUnderlay onClick={() => setIsModalOpen(false)} />
      <Tab subtitle={subtitle} title={title} />
      <Scrollable hasMaxHeight={true}>{children}</Scrollable>
      <Placeholder />
    </>
  )
}

const TabWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  padding: ${sizes.padding}px ${sizes.padding}px 0 ${sizes.padding}px;
  background-color: ${colors.background};
  border-radius: 20px 20px 0 0;
  h2 {
    margin: 0;
  }
`
const TabUnderlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
`

const Placeholder = styled.div`
  background-color: ${colors.background};
  height: 50px;
  position: absolute;
  bottom: 0;
  width: 100%;
`

export default WorkContentWrapper
