// 3rd party
import React from "react"

function Medium({isLarge}) {
  return (
    <svg height={isLarge ? 28 : 15} version="1.1" viewBox="0 0 137.85 24.897" xmlns="http://www.w3.org/2000/svg" fill="#fff">
      <g transform="matrix(1.3333 0 0 -1.3333 -406.9 889.2)">
        <g transform="translate(305.18 665.2)">
          <path d="m0 0v1.694h3.834l5.278-10.88 5.229 10.88h3.834v-1.694h-1.568v-15.284h1.568v-1.694h-5.228v1.694h1.617v12.82l-4.705-9.685h-1.567l-4.682 9.76v-12.895h1.619v-1.694h-5.229v1.694h1.569v15.284z" />
        </g>
        <g transform="translate(337.01 660.5)">
          <path d="m0 0v-5.227h-1.693v1.768h-3.511v-7.12h6.374v2.912h1.694v-4.606h-11.678v1.694h1.569v15.284h-1.569v1.694h11.678v-4.605h-1.694v2.911h-6.374v-6.471h3.511v1.766z" />
        </g>
        <g transform="translate(242.57 297.16)">
          <g transform="translate(110.44 369.74)">
            <path d="m0 0c2.465 0 3.261-1.071 3.261-3.261v-12.149c0-2.291-1.045-3.262-3.261-3.262h-8.788v1.694h1.568v15.284h-1.568v1.694zm1.22-1.694h-6.399v-15.284h6.399z" />
          </g>
        </g>
        <g transform="translate(364.97 649.92)">
          <path d="m0 0h1.619v-1.694h-5.228v1.694h1.568v15.284h-1.568v1.694h5.228v-1.694h-1.619z" />
        </g>
        <g transform="translate(242.57 297.16)">
          <g transform="translate(129.11 369.74)">
            <path d="m0 0h5.228v-1.694h-1.619v-15.284h6.773v15.284h-1.618v1.694h5.228v-1.694h-1.569v-14.388c0-1.795-0.746-2.59-2.241-2.59h-6.398c-1.519 0-2.216 0.896-2.216 2.466v14.512h-1.568z" />
          </g>
        </g>
        <g transform="translate(390.39 665.2)">
          <path d="m0 0v1.694h3.834l5.279-10.88 5.228 10.88h3.834v-1.694h-1.568v-15.284h1.568v-1.694h-5.228v1.694h1.618v12.82l-4.706-9.685h-1.568l-4.681 9.76v-12.895h1.62v-1.694h-5.23v1.694h1.569v15.284z" />
        </g>
      </g>
    </svg>
  )
}

export default Medium
