import WorkContentItem from "../work/workContent/workContentItem"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import React, {useContext, useEffect, useState} from "react"
import {useStaticQuery, graphql as gql} from "gatsby"

function Info() {
  let appState = useContext(GlobalStateContext)
  let data = useStaticQuery(query)
  let {quotes} = data
  quotes = quotes.edges
  let [index, setIndex] = useState(0)
  useEffect(() => {
    quotes.length && setIndex(Math.ceil(Math.random() * quotes.length) - 1)
  }, [appState.isInfoOpen, quotes])
  let quoteName = !!quotes[index].node.name && `<p>${quotes[index].node.name}</p>`
  let quote = quotes && quotes[index].node.content + quoteName
  return <>{appState.isInfoOpen && quotes && <WorkContentItem content={quote} activeItem="description" />}</>
}

const query = gql`
  query Quotes {
    quotes: allWpQuote {
      edges {
        node {
          name: title
          content
        }
      }
    }
  }
`

export default Info
