// 3rd party
import React from "react"

function I() {
  return (
    <svg height="12" version="1.1" viewBox="0 0 7.9 17.6" xmlns="http://www.w3.org/2000/svg" fill="#fff">
      <path d="m3.8 0c1.1 0 2 0.8 2 1.9s-0.9 2-2 2-1.9-0.9-1.9-2 0.8-1.9 1.9-1.9zm4.1 17.6h-7.9v-1.7h1c1.3 0 1.5-0.4 1.5-1.6v-5.7c0-1.2-0.2-1.6-1.5-1.6h-1v-1.7h5.6v9c0 1.2 0.2 1.6 1.5 1.6h0.8z" />
    </svg>
  )
}

export default I
