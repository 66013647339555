import React, {useContext} from "react"
import {GlobalDispatchContext, GlobalStateContext} from "../../context/GlobalContextProvider"
import {colors} from "../../constants/css"

const Glass = () => {
  const appState = useContext(GlobalStateContext)
  const appDispatch = useContext(GlobalDispatchContext)

  return (
    <svg
      onClick={() => appDispatch({type: "setSearchIsActive", value: !appState.searchIsActive})}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 27.2 13.8"
      style={{enableBackground: "new 0 0 27.2 13.8", height: "23px", cursor: "pointer"}}
    >
      <path
        fill={colors.white}
        className="st0"
        d="M16.1,11.2c-2.3-2.3-2.3-6,0-8.4c2.3-2.3,6-2.3,8.4,0s2.3,6,0,8.4C22.2,13.5,18.4,13.5,16.1,11.2 M25.3,2
	c-2.7-2.7-7.1-2.7-9.8,0c-1.4,1.4-2,3.1-2,4.8H5.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.2,0.1,0.3,0.2,0.4C4.8,7.9,5,7.9,5.1,7.9h8.4
	c0.2,1.4,0.8,2.7,2,3.8c2.7,2.7,7.1,2.7,9.8,0C27.9,9.1,27.9,4.8,25.3,2"
      />
      <path
        fill={colors.white}
        className="st0"
        d="M11.3,8.5H0.8C0.4,8.5,0,8.2,0,7.8V7.2c0-0.5,0.3-0.8,0.8-0.8h10.6c0.4,0,0.8,0.3,0.8,0.8v0.6
	C12.1,8.2,11.7,8.5,11.3,8.5"
      />
      <path
        fill={appState.searchIsActive && !!appState.searchQuery ? colors.red : "transparent"}
        className="st1"
        d="M16.1,11.2c-2.3-2.3-2.3-6,0-8.4c2.3-2.3,6-2.3,8.4,0s2.3,6,0,8.4C22.2,13.5,18.4,13.5,16.1,11.2"
      />
    </svg>
  )
}
export default Glass
