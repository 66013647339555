// 3rd party
import React, {useContext} from "react"
import styled from "styled-components"

// utils, constants
import {colors, device, sizes} from "../../constants/css"
import {getAttached} from "../../lib/dataManipulation"

// context
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {NavStateContext} from "../../context/NavContextProvider"

// components
import Scrollable from "../general/scrollable"
import NavPreviewContent from "./navPreviewContent"

const NavPreview = () => {
  // state
  const appState = useContext(GlobalStateContext)
  const navState = useContext(NavStateContext)

  // data
  const {previewPost} = navState
  const attached = getAttached(previewPost, appState.navData, appState.navType)

  return (
    <Preview className="from-laptop">
      <Scrollable>
        {previewPost && <NavPreviewContent post={previewPost} attached={attached} navType={appState.navType} />}
      </Scrollable>
    </Preview>
  )
}

const Preview = styled.div`
  z-index: 5;
  width: ${sizes.nav2Width}px;
  height: calc(100vh - calc(100vh - 100%));
  padding: 26px 27px 15px 22px;
  background-color: ${colors.background};
  position: fixed;
  top: 0;
  //left: ${sizes.nav0Width.small + sizes.nav1Width.small + sizes.navDiamondWidth}px;
  @media ${device.mobileL} {
    left: ${sizes.nav0Width.large + sizes.nav1Width.large + sizes.navDiamondWidth}px;
  }
`

export default NavPreview
