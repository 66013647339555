// 3rd party
import React, {useContext, useRef, useState} from "react"
import styled, {css} from "styled-components"
import Draggable from "react-draggable"

// constnts, context
import {colors, device, sizes, transition} from "../../constants/css"
import {GlobalDispatchContext, GlobalStateContext} from "../../context/GlobalContextProvider"
import {MenuDispatchContext, MenuStateContext} from "../../context/MenuContextProvider"

// components
import SearchResults from "./search/searchResults"
import MenuDiamond from "./menuDiamond"
import Hamburger from "./hamburger"
import About from "./about"
import MenuTop from "./menuTop"
import MenuBottom from "./menuBottom"
import HandleClickOutside from "../general/handleClickOutside"

const Menu = ({isDraggable = false}) => {
  const appDispatch = useContext(GlobalDispatchContext)
  const appState = useContext(GlobalStateContext)
  const menuDispatch = useContext(MenuDispatchContext)
  const menuState = useContext(MenuStateContext)

  const [hasSearchBeenFocussed, setHasSearchBeenFocussed] = useState(false)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  let menuRef = useRef()

  function onDrop(event) {}

  function onDrag() {
    setIsDragging(true)
  }

  function onStop(...args) {
    setTimeout(() => {
      setIsDragging(false)
    }, 100)
    if (isDragging) {
      onDrop(...args)
    } else {
    }
  }
  let inner = (
    <MenuWrapper {...{isDraggable}} navIsCollapsed={appState.navIsCollapsed} ref={menuRef}>
      <MenuDiamond>
        <Hamburger
          isOpen={menuState.menuIsVisible}
          onClick={() => !isDragging && menuDispatch({type: "toggleMenu"})}
          isVisible={menuState.hamburgerIsVisible || !isDraggable}
          transformY={true}
        />
      </MenuDiamond>
      <TheMenu isVisible={menuState.menuIsVisible}>
        <MenuInner>
          <MenuTop {...{isDragging}} />
          <MenuBottom {...{isDragging, setIsDragging, setHasSearchBeenFocussed}} isInfoOpen={appState.isInfoOpen} />
        </MenuInner>
        <About />
        {hasSearchBeenFocussed && <SearchResults isLoading={isSearchLoading} setIsLoading={setIsSearchLoading} />}
      </TheMenu>
    </MenuWrapper>
  )
  return isDraggable ? (
    <div className="from-mobile-l">
      <HandleClickOutside
        onClickOutside={() => {
          let isVisible = menuRef.current?.offsetWidth
          if (!!isVisible) {
            // only handle click outside on larger than mobile
            appState.searchIsActive && appDispatch({type: "setSearchIsActive", value: false})
            appState.isInfoOpen && appDispatch({type: "setIsInfoOpen", value: false})
          }
        }}
      >
        <Draggable bounds="body" cancel=".noDrag" onDrag={onDrag} onStop={onStop}>
          {inner}
        </Draggable>
      </HandleClickOutside>
    </div>
  ) : (
    <div className="until-mobile-l">{inner}</div>
  )
}

const MenuInner = styled.div`
  background-color: ${colors.background};
  transform-origin: 75% -30%;
  border: 2px solid ${colors.white};
  border-bottom: 2px solid ${colors.white};
  transition: all ${transition.fast}ms;
`
const TheMenu = styled.div`
  transition: all ${transition.fast}ms ease-out;
  transform: scale(1);
  transform-origin: 73% 35%;
  ${({isVisible}) =>
    isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    `}
  ${({isVisible}) =>
    !isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
    `}
`

const MenuWrapper = styled.div`
  //display: ${({isDraggable}) => (isDraggable ? "none" : "block")};
  //visibility: ${({isDraggable}) => (isDraggable ? "hidden" : "visible")};
  z-index: 10;
  position: absolute;
  top: 42px;
  left: 20px;
  transition: ${transition.nav}ms;
  transform: translateX(${({navIsCollapsed}) => (navIsCollapsed ? -100 : 0)}%);
  @media ${device.mobileL} {
    transition: 0s;
    transform: none;
    position: fixed;
    //display: ${({isDraggable}) => (!isDraggable ? "none" : "block")};
    //visibility: ${({isDraggable}) => (!isDraggable ? "hidden" : "visible")};
    cursor: move;
    z-index: 2;
    left: auto;
    right: 40px;
    top: 50px;
  }
  width: ${sizes.menuWidth}px;
`

const StyledMenu = styled(Menu)`
  transform: scale(1);
`

export default StyledMenu
