import React from "react"
import styled from "styled-components"
import ExternalLink from "../general/externalLink"

function Socials({contact}) {
  return (
    <ul style={{display: "flex"}}>
      {["facebook", "instagram", "twitter", "youtube", "linkedin", "patreon", "phone"].map(social => {
        let url = (social === "phone" ? "tel:" : "") + contact[social]?.replaceAll(" ", "")
        return (
          contact[social] && (
            <Li key={social}>
              <ExternalLink url={url}>
                <img height="32" src={`/icons/socials/${social}.svg`} alt={social} />
              </ExternalLink>
            </Li>
          )
        )
      })}
    </ul>
  )
}
const Li = styled.li`
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
`

export default Socials
