import React from "react"
import styled from "styled-components"
import {colors} from "../../constants/css"

const Diamond = ({translate, top, right, colorLeft = colors.red, colorRight = colors.red}) => {
  return (
    <svg
      translate={translate}
      top={top}
      right={right}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16.5 20.5"
    >
      <LeftPath
        color={colorLeft}
        d="M9,0.3c-0.4-0.4-1.1-0.4-1.5,0L0.4,6.9C0,7.3-0.1,8,0.2,8.4l7.5,11.7c0.3,0.5,0.8,0.5,1.1,0L9,0.3z"
      />
      <RightPath
        color={colorRight}
        d="M7.7,20.1c0.3,0.5,0.8,0.5,1.1,0l7.5-11.7c0.3-0.5,0.2-1.1-0.2-1.5L9,0.3c-0.4-0.4-1.1-0.4-1.5,0L7.7,20.1z"
      />
    </svg>
  )
}

const LeftPath = styled.path`
  fill: ${({color}) => color};
`

const RightPath = styled.path`
  fill: ${({color}) => color};
`

export default Diamond
