import {colors, transition} from "../../constants/css"
import {GlobalDispatchContext, GlobalStateContext} from "../../context/GlobalContextProvider"
//import {MenuDispatchContext} from "../../context/MenuContextProvider"
import React, {useContext, useRef} from "react"
import styled from "styled-components"
import Glass from "../icons/glass"
import SearchInput from "./search/searchInput"
import I from "../icons/i"

function MenuBottom({isSearchLoading, setHasSearchBeenFocussed, isDragging}) {
  const appState = useContext(GlobalStateContext)
  const appDispatch = useContext(GlobalDispatchContext)
  const inputRef = useRef()
  const onClick = () => {
    if (!isDragging) {
      appDispatch({type: "setIsInfoOpen", value: !appState.isInfoOpen})
      appDispatch({type: "setSearchIsActive", value: false})
    }
  }
  return (
    <BottomMenu>
      <BottomMenuInner>
        <StyledGlass>
          <Glass />
        </StyledGlass>
        <SearchInput inputRef={inputRef} isLoading={isSearchLoading} setHasBeenFocussed={setHasSearchBeenFocussed} />
        {isSearchLoading && appState.searchQuery && <Loader />}
        <AboutOpener onClick={onClick} onTouchEnd={onClick} isInfoOpen={appState.isInfoOpen}>
          <AboutOpenerInner>
            <I />
          </AboutOpenerInner>
        </AboutOpener>
      </BottomMenuInner>
    </BottomMenu>
  )
}

const BottomMenu = styled.div`
  height: 24px;
`

const BottomMenuInner = styled.div`
  position: relative;
  transition: all ${transition.fast}ms ease-out;
  display: grid;
  grid-template-columns: 50px 1fr 30px;
  height: 23px;
`

const Loader = styled.div`
  position: absolute;
  left: 27px;
  top: 6px;
  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  display: inline-block;
  width: 8px;
  height: 8px;
  &:after {
    content: " ";
    display: block;
    width: 8px;
    height: 8px;

    border-radius: 50%;
    border: 3px solid ${colors.white};
    border-color: ${colors.white} transparent ${colors.white} transparent;
    animation: dual-ring 900ms linear infinite;
  }
`
const AboutOpener = styled.button`
  border-left: 2px solid ${colors.white};
  background-color: ${({isInfoOpen}) => (isInfoOpen ? colors.red : "transparent")};
  position: relative;
`
const AboutOpenerInner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledGlass = styled.div`
  height: 100%;
  svg {
    position: absolute;
  }
`

export default MenuBottom
