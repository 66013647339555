// 3rd party
import React, {useEffect} from "react"
import loadable from "@loadable/component"

// styles
import GlobalStyle from "../../styles/styles"

// context
import GlobalContextProvider from "../../context/GlobalContextProvider"
import NavContextProvider from "../../context/NavContextProvider"
import MenuContextProvider from "../../context/MenuContextProvider"

// components
import Menu from "../menu/menu"
import Nav from "../nav"
import Overlay from "../general/overlay"
import Info from "../menu/info"
//const LazyAttachedOnPage = React.lazy(() => import("../attached/attachedOnPage"))
const LazyAttachedOnPage = loadable(() => import("../attached/attachedOnPage"))

const Layout = ({children}) => {
  function handleResize() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  useEffect(() => {
    function resizeAndScroll() {
      if (typeof window !== "undefined") {
        handleResize()
        window.addEventListener("resize", handleResize)
      }
    }
    resizeAndScroll()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <GlobalContextProvider>
      <GlobalStyle />
      <Overlay>
        {children}
        <Info />
        {typeof window !== "undefined" && <LazyAttachedOnPage />}
      </Overlay>
      <MenuContextProvider>
        <NavContextProvider>
          <Menu isDraggable={true} />
          <Nav>
            <Menu />
          </Nav>
        </NavContextProvider>
      </MenuContextProvider>
    </GlobalContextProvider>
  )
}

export default Layout
